import logoIcon from "assets/images/logo-icon.png"
import { KLogo } from "kahuna-base-react-components"
import TunefundLogo from "./TunefundLogo"

const LogoIcon = () => {
  return <TunefundLogo width={24} textVisible={false} />
  // <KLogo logoColor={"black"} width={48} borderRadius={40} primaryTextVisible={true} primaryTextWidth={100} />
}

export default LogoIcon
