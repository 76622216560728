import { Box, ButtonBase, ClickAwayListener, Grid, Paper, Popper, Stack, Tab, Tabs, Typography } from "@mui/material"
import MainCard from "components/MainCard"
import Avatar from "../../../../../components/@extended/Avatar"
import avatar1 from "../../../../../assets/images/users/avatar-6.png"
import { Logout, Sort, Setting2, DeviceMessage, Cardano, ShieldSecurity, People, Tag, Money } from "iconsax-react"
import useAuth from "../../../../../hooks/useAuth"
import { useNavigate } from "react-router"
import { ThemeMode } from "../../../../../types/config"
import { useTheme } from "@mui/material/styles"
import { useRef, useState } from "react"
import Transitions from "components/@extended/Transitions"
import { a11yProps } from "../../Header/HeaderContent/Profile"
import { UserRoles } from "types/auth"

const ProfileCard = () => {
  const { logout, user } = useAuth()
  const navigate = useNavigate()
  const theme = useTheme()

  const anchorRef = useRef<any>(null)
  const [open, setOpen] = useState(false)
  const [selectedMenuItem, setSelectedMenuItem] = useState(1)
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }
  const handleLogout = async () => {
    try {
      await logout()
      navigate(`/login`, {
        state: {
          from: ""
        }
      })
    } catch (err) {
      console.error(err)
    }
  }

  const onClickNavigation = (url: string, selectedValue: number) => {
    setOpen(false)
    navigate(url)
    setSelectedMenuItem(selectedValue)
  }

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }
    setOpen(false)
  }

  return (
    <>
      <MainCard sx={{ bgcolor: "secondary.200", m: 0, border: "none" }}>
        <Stack alignItems="center" spacing={2.5}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Stack direction="row" spacing={1.25} alignItems="center">
                <Avatar alt="profile user" src={avatar1} />
                <Typography variant="subtitle1">{user?.first_name}</Typography>
              </Stack>
            </Grid>
            <Grid item>
              <ButtonBase
                sx={{
                  p: 0.25,
                  borderRadius: 1,
                  "&:hover": {
                    bgcolor: theme.palette.mode === ThemeMode.DARK ? "secondary.light" : "secondary.lighter"
                  },
                  "&:focus-visible": {
                    outline: `2px solid ${theme.palette.secondary.dark}`,
                    outlineOffset: 2
                  }
                }}
                aria-label="open profile"
                ref={anchorRef}
                aria-controls={open ? "profile-grow" : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
              >
                <Sort />
              </ButtonBase>
            </Grid>
          </Grid>
        </Stack>
      </MainCard>
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        style={{ zIndex: 999 }}
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, 8]
              }
            }
          ]
        }}
      >
        {({ TransitionProps }) => (
          <Transitions type="grow" position="top-right" in={open} {...TransitionProps}>
            <Paper
              sx={{
                boxShadow: theme.customShadows.z1,
                width: 250,
                [theme.breakpoints.down("md")]: {
                  maxWidth: 250
                },
                borderRadius: 1.5
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs variant="standard" aria-label="profile tabs" value={selectedMenuItem} orientation={"vertical"}>
                    <Tab
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "start",
                        alignItems: "center",
                        textTransform: "capitalize"
                      }}
                      onClick={handleLogout}
                      icon={<Logout size={18} style={{ marginBottom: 0, marginRight: "10px" }} />}
                      label="Logout"
                      {...a11yProps(0)}
                    />
                    <Tab
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "start",
                        alignItems: "center",
                        textTransform: "capitalize"
                      }}
                      onClick={() => onClickNavigation("/", 1)}
                      icon={<Cardano size={18} style={{ marginBottom: 0, marginRight: "10px" }} />}
                      label="Dashboard"
                      {...a11yProps(0)}
                    />
                    {user?.role_ids?.includes(UserRoles.SCOUTING_ADMIN) && (
                      <Tab
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "start",
                          alignItems: "center",
                          textTransform: "capitalize"
                        }}
                        onClick={() => onClickNavigation("/admin/system-settings", 2)}
                        icon={<Setting2 size={18} style={{ marginBottom: 0, marginRight: "10px" }} />}
                        label="System Settings"
                        {...a11yProps(0)}
                      />
                    )}
                    {user?.role_ids?.includes(UserRoles.SCOUTING_ADMIN) && (
                      <Tab
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "start",
                          alignItems: "center",
                          textTransform: "capitalize"
                        }}
                        onClick={() => onClickNavigation("/admin/scheduler-logs", 3)}
                        icon={<DeviceMessage size={18} style={{ marginBottom: 0, marginRight: "10px" }} />}
                        label="Scheduler Logs"
                        {...a11yProps(0)}
                      />
                    )}
                    {user?.role_ids?.includes(UserRoles.SCOUTING_ADMIN) && (
                      <Tab
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "start",
                          alignItems: "center",
                          textTransform: "capitalize"
                        }}
                        onClick={() => onClickNavigation("/admin/allowed-offer-links", 4)}
                        icon={<ShieldSecurity size={18} style={{ marginBottom: 0, marginRight: "10px" }} />}
                        label="Allowed Offer Links"
                        {...a11yProps(0)}
                      />
                    )}
                    {user?.role_ids?.includes(UserRoles.SCOUTING_ADMIN) && (
                      <Tab
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "start",
                          alignItems: "center",
                          textTransform: "capitalize"
                        }}
                        onClick={() => onClickNavigation("/admin/unit-price", 6)}
                        icon={<Money size={18} style={{ marginBottom: 0, marginRight: "10px" }} />}
                        label="Estimated Offer DSP Prices"
                        {...a11yProps(0)}
                      />
                    )}
                    {user?.role_ids?.includes(UserRoles.SCOUTING_ADMIN) && (
                      <Tab
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "start",
                          alignItems: "center",
                          textTransform: "capitalize"
                        }}
                        onClick={() => onClickNavigation("/admin/tags", 5)}
                        icon={<Tag size={18} style={{ marginBottom: 0, marginRight: "10px" }} />}
                        label="Tags"
                        {...a11yProps(0)}
                      />
                    )}
                    {user?.role_ids?.includes(UserRoles.SCOUTING_ADMIN) && (
                      <Tab
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "start",
                          alignItems: "center",
                          textTransform: "capitalize"
                        }}
                        onClick={() => onClickNavigation("/admin/users", 7)}
                        icon={<People size={18} style={{ marginBottom: 0, marginRight: "10px" }} />}
                        label="User List"
                        {...a11yProps(0)}
                      />
                    )}
                  </Tabs>
                </Box>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </>
  )
}

export default ProfileCard
