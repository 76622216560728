export const CurrencyMap = {
  "N/A": "?", // Unkown
  USD: "$", // US Dollar
  EUR: "€", // Euro
  GBP: "£", // British Pound
  JPY: "¥", // Japanese Yen
  AUD: "A$", // Australian Dollar
  CAD: "C$", // Canadian Dollar
  CHF: "CHF", // Swiss Franc
  CNY: "¥", // Chinese Yuan
  SEK: "kr", // Swedish Krona
  NZD: "NZ$", // New Zealand Dollar
  INR: "₹", // Indian Rupee
  RUB: "₽", // Russian Ruble
  ZAR: "R", // South African Rand
  BRL: "R$", // Brazilian Real
  MXN: "Mex$", // Mexican Peso
  TRY: "₺", // Turkish Lira
  TL: "₺", // Turkish Lira
  KRW: "₩", // South Korean Won
  SGD: "S$", // Singapore Dollar
  HKD: "HK$", // Hong Kong Dollar
  NOK: "kr", // Norwegian Krone
  DKK: "kr", // Danish Krone
  PLN: "zł", // Polish Zloty
  THB: "฿", // Thai Baht
  MYR: "RM", // Malaysian Ringgit
  IDR: "Rp", // Indonesian Rupiah
  SAR: "﷼", // Saudi Riyal
  AED: "د.إ", // UAE Dirham
  ARS: "$", // Argentine Peso
  EGP: "£", // Egyptian Pound
  PHP: "₱", // Philippine Peso
  VND: "₫" // Vietnamese Dong
}
