import { User } from "./users"
import { Artist } from "./artists"
import { PaginatedData } from "./root"
import { SpotifyArtistRequest } from "./spotifyArtistRequest"

export enum ReportTabs {
  UNDEFINED = 0,
  TRACK_VALUATION_BUBBLE = 1,
  LTM_AND_FORECAST = 2,
  INCOME_AND_FORECAST = 3,
  MONTHLY_INCOME_AND_LISTENERS = 4,
  TOTAL_INCOME_AND_LISTENERS = 5,
  PLATFORM_AND_TERRITORY = 6,
  DISTRIBUTOR_PERCENTAGE = 7,
  TRACKS_INCOME_AND_LISTENER_PERCENTAGE = 8,
  PLATFORM_MONTHLY_INCOME = 9,
  PLATFORM_MONTHLY_LISTENER = 10,
  TRACK_TIMELINE = 11
}

export class AdminSongSelectionWidth {
  checkbox = "3%"
  link = "4%"
  isrc = ""
  title = "18%"
  albumId = "4%"
  ownership = "3%"
  userApproved = "3%"
  confirmedOffer = "7%"
  offerUsd = ""
  advanceUsd = "6%"
  buyoutUsd = "6%"
  releaseDAte = "8%"
  streamCount = "7%"
  streamDate = "8%"
  lmLsmLtmStream = "8%"
  lmLsmLtmIncome = "8%"
  Ltm456Income = "8%"
}

export class UserSongSelectionWidth {
  checkbox = "4%"
  link = "5%"
  isrc = ""
  title = "25%"
  ownership = ""
  userApproved = ""
  albumId = "5%"
  confirmedOffer = "7%"
  offerUsd = "7%"
  advanceUsd = ""
  buyoutUsd = ""
  releaseDAte = "8%"
  streamCount = "7%"
  streamDate = "8%"
  lmLsmLtmStream = "8%"
  lmLsmLtmIncome = "8%"
  Ltm456Income = "8%"
}

export enum ConfirmedOfferTypes {
  ADVANCE = "advance",
  BUYOUT = "buyout"
}

export enum RoyaltyReportTypes {
  INDIVIDUAL = "individual",
  LABEL = "label"
}

export type RoyaltyReportRow = {
  distributor: string
  period: string
  platform: string
  transactionDate: string
  songTitle: string
  isrc: string
  created: string
  territory: string
  quantity: number
  currency: string
  segment: string
  segmentDescription: string
  netQuantity: number
  grossProgress: number
  netProgress: number
  splitRate: number
  channelId: string
  isVideo: boolean
}

export type RoyaltyReport = {
  user: User
  userId: number
  sheetId: string
  spotifyId: string
  artist: Artist
  progress: number
  validIsrc: boolean
  created: string
  distributor: string
  type: string
  currency: string
  unitPrice: number
}

export interface ConfirmedOfferOption {
  id: number
  name: string
  value: string
  description: string
  multiplier: number
  type: string
  primary: boolean
}

export interface ConfirmedOffer {
  id: number
  spotifyId: string
  created: string
  advanceOfferUsd: number
  buyoutOfferUsd: number
  approved: boolean
  artist: Artist
  user: User
  userApproved: boolean
  selectedOption: ConfirmedOfferOption
  confirmedTotal: number
  type: string
  spotifyArtistAccount: SpotifyArtistRequest
}

export type ReportsContextType = {
  paginatedConfirmedOfferList: PaginatedData<ConfirmedOffer>
  fetchConfirmedOfferList: (
    orderBy: string,
    limit: number,
    offset: number,
    isPublic: boolean,
    artist?: string,
    spotifyId?: string,
    username?: string,
    id?: number,
    userId?: number
  ) => Promise<void>
  updateConfirmedOffer: (id: number, approved: boolean, type: string, offerUsd?: number) => Promise<boolean>
  updateSongSelectionOffer: (confirmedOfferId: number, songSelectionId: number, offer: number) => Promise<boolean>
}

export interface ReportsProps {
  paginatedConfirmedOfferList: PaginatedData<ConfirmedOffer>
}

export interface ReportsActionProps {
  type: string
  payload?: ReportsProps
}

export interface ConfirmedOfferTransferRequest {
  confirmedOfferId: number
  spotifyAccountId: number
  firstName: string
  lastName: string
  email: string
  password: string
  spotifyId: string
  allowedOfferLinkCode: string
  overrideAllowedOfferLinkCode: boolean
  duplicateOffer: boolean
  [key: string]: string | number | boolean
}
