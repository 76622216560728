import { KSelectOption } from "kahuna-base-react-components/dist/components/KDropdown/KDropdown"
import { ReactElement } from "react"

// ==============================|| TYPES - AUTH  ||============================== //

export const allowedRoles = (user?: UserProfile) => {
  if (
    user?.role_ids?.includes(UserRoles.SCOUTING_ACCESS) ||
    user?.role_ids?.includes(UserRoles.BLACK_LIST_VALIDATOR) ||
    user?.role_ids?.includes(UserRoles.ARTIST_VALIDATOR) ||
    user?.role_ids?.includes(UserRoles.BUYOUT_OFFER)
  ) {
    return true
  }
  return false
}

export enum UserRoles {
  SCOUTING_ADMIN = 1,
  SCOUTING_ACCESS = 2,
  BLACK_LIST_VALIDATOR = 3,
  ARTIST_VALIDATOR = 4,
  BUYOUT_OFFER = 5,
  OFFERS_ACCESS = 10,
  SIMENA_ACCESS = 20
}

export const roleOptions: KSelectOption[] = [
  { label: "Scouting Access", value: UserRoles.SCOUTING_ACCESS },
  { label: "BlackList Validator", value: UserRoles.BLACK_LIST_VALIDATOR },
  { label: "Artist Inbox Validator", value: UserRoles.ARTIST_VALIDATOR },
  { label: "Buyout Offer", value: UserRoles.BUYOUT_OFFER },
  { label: "Offer Access", value: UserRoles.OFFERS_ACCESS },
  { label: "Simena Access", value: UserRoles.SIMENA_ACCESS }
]

export type GuardProps = {
  children: ReactElement | null
}

export type UserProfile = {
  id?: string
  email?: string
  first_name?: string
  last_name?: string
  avatar?: string
  image?: string
  role?: string
  tier?: string
  role_ids?: number[]
}

export interface AuthProps {
  isLoggedIn: boolean
  isInitialized?: boolean
  roleIds?: number[]
  user?: UserProfile | null
  token?: string | null
}

export interface AuthActionProps {
  type: string
  payload?: AuthProps
}

export interface InitialLoginContextProps {
  isLoggedIn: boolean
  isInitialized?: boolean
  user?: UserProfile | null | undefined
}

export interface JWTDataProps {
  userId: string
}

interface OffersAuthProps {
  user_existed: boolean
  success: boolean
}

export type JWTContextType = {
  isLoggedIn: boolean
  isInitialized?: boolean
  roleIds?: number[]
  user?: UserProfile | null | undefined
  logout: () => void
  login: (email: string, password: string) => Promise<void>
  loginSpotify: (code: string) => Promise<void>
  sendCodeValidation: (username: string, password: string) => Promise<boolean>
  validateEmailAddress: (email: string, code: string) => Promise<boolean>
  updateProfile: VoidFunction
}
