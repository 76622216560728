import { useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom"

// project-imports
import { SCOUTING_DEFAULT_PATH } from "config"
import useAuth from "hooks/useAuth"

// types
import { allowedRoles, GuardProps, UserRoles } from "types/auth"
import { enqueueSnackbar } from "notistack"

// ==============================|| GUEST GUARD ||============================== //

const GuestGuard = ({ children }: GuardProps) => {
  const { isLoggedIn, user, logout } = useAuth()
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if (isLoggedIn && allowedRoles(user || undefined)) {
      navigate(location?.state?.from ? location?.state?.from : SCOUTING_DEFAULT_PATH, {
        state: {
          from: ""
        },
        replace: true
      })
    } else if (isLoggedIn && !allowedRoles(user || undefined)) {
      logout()
      enqueueSnackbar(`Not Allowed!`, {
        variant: "error",
        preventDuplicate: true,
        anchorOrigin: { vertical: "top", horizontal: "right" }
      })
      setTimeout(() => {
        window.location.reload()
      }, 1000)
    }
  }, [isLoggedIn, navigate, location])

  return children
}

export default GuestGuard
