import { createContext, ReactElement, useReducer } from "react"
import axios from "utils/axios"
import { FETCH_ALLOWED_OFFER_LINKS, FETCH_ALBUMS_DEFAULT_FILTERS } from "../store/reducers/actions"
import { mapPaginatedDataToCamelCase, mapToCamelCase } from "../utils/caseTransformation"
import { PaginatedData } from "types/root"
import { getFilterText } from "../utils"
import { AllowedOffersLink, DefaultAlbumFilters, SystemContextType } from "../types/system"
import systemsReducer, { initialSystemState } from "../store/reducers/system"

const SystemContext = createContext<SystemContextType | null>(null)

export const SystemsProvider = ({ children }: { children: ReactElement }) => {
  const [state, dispatch] = useReducer(systemsReducer, initialSystemState)

  const fetchAllowedOffersLinks = async (limit: number, offset: number, filter?: any, orderBy?: string) => {
    const response = await axios(
      `/system/allowed-offer-links/?limit=${limit}&offset=${offset}${getFilterText(filter)}${
        orderBy ? `&order_by=${orderBy}` : ""
      }`,
      { method: "get" }
    )

    dispatch({
      type: FETCH_ALLOWED_OFFER_LINKS,
      payload: {
        ...state,
        paginatedAllowedOffersLinks: mapPaginatedDataToCamelCase<PaginatedData<AllowedOffersLink>>(response.data)
      }
    })
  }

  const saveAllowedOffersLink = async (allowedOfferLink: AllowedOffersLink) => {
    const response = await axios(`/system/allowed-offer-links/save/`, { method: "post", data: { ...allowedOfferLink } })
    if (!response.data.entity) {
      return false
    }

    const results = [...state.paginatedAllowedOffersLinks.results]
    if (!allowedOfferLink.id) {
      results.unshift(mapToCamelCase(response.data.entity))
    } else {
      const allowedOfferLink = mapToCamelCase(response.data.entity)
      results.forEach((result) => {
        if (result.id === allowedOfferLink.id) {
          result.expireDate = allowedOfferLink.expireDate
        }
        return result
      })
    }

    dispatch({
      type: FETCH_ALLOWED_OFFER_LINKS,
      payload: {
        ...state,
        paginatedAllowedOffersLinks: { ...state.paginatedAllowedOffersLinks, results: [...results] }
      }
    })

    return true
  }

  const deleteAllowedOffersLink = async (id: number) => {
    const response = await axios(`/system/allowed-offer-links/remove/?id=${id}`, { method: "delete" })
    if (!response.data.success) {
      return false
    }

    const results = state.paginatedAllowedOffersLinks.results.filter((result) => result.id !== id)

    dispatch({
      type: FETCH_ALLOWED_OFFER_LINKS,
      payload: {
        ...state,
        paginatedAllowedOffersLinks: { ...state.paginatedAllowedOffersLinks, results: [...results] }
      }
    })

    return true
  }

  const fetchAlbumDefaultFilters = async () => {
    const response = await axios(`/system/setting/album_default_filters/`, { method: "get" })

    dispatch({
      type: FETCH_ALBUMS_DEFAULT_FILTERS,
      payload: { ...state, defaultAlbumFilters: mapToCamelCase(response.data.result) as DefaultAlbumFilters }
    })
  }

  return (
    <SystemContext.Provider
      value={{
        ...state,
        fetchAllowedOffersLinks,
        saveAllowedOffersLink,
        deleteAllowedOffersLink,
        fetchAlbumDefaultFilters
      }}
    >
      {children}
    </SystemContext.Provider>
  )
}

export default SystemContext
