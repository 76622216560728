import { createContext, ReactElement, useReducer } from "react"
import albumsReducer, { initialAlbumsState } from "../store/reducers/albums"
import axios from "utils/axios"
import { FETCH_ALBUMS, UPDATE_ALBUM_TAKEDOWN_STATUS } from "../store/reducers/actions"
import { mapPaginatedDataToCamelCase, mapToCamelCase } from "../utils/caseTransformation"
import { PaginatedData } from "types/root"
import { getFilterText, toastMessage } from "../utils"
import { Album, AlbumsContextType } from "../types/albums"
import { JobReport } from "types/constants"

const AlbumsContext = createContext<AlbumsContextType | null>(null)

export const AlbumsProvider = ({ children }: { children: ReactElement }) => {
  const [state, dispatch] = useReducer(albumsReducer, initialAlbumsState)

  const fetchAlbums = async (limit: number, offset: number, filter?: any, orderBy?: string) => {
    const tagsIncludedParam = filter.tagsIncluded?.length > 0 ? `&tags_included=${filter.tagsIncluded.join(",")}` : ""
    const tagsExcludedParam = filter.tagsExcluded?.length > 0 ? `&tags_excluded=${filter.tagsExcluded.join(",")}` : ""
    const filteredFilter = { ...filter, tagsIncluded: undefined, tagsExcluded: undefined }
    const response = await axios(
      `/albums/?limit=${limit}&offset=${offset}${getFilterText(
        filteredFilter
      )}${tagsIncludedParam}${tagsExcludedParam}${orderBy ? `&order_by=${orderBy}` : ""}`,
      { method: "get" }
    )

    dispatch({
      type: FETCH_ALBUMS,
      payload: { ...state, paginatedAlbums: mapPaginatedDataToCamelCase<PaginatedData<Album>>(response.data) }
    })
  }

  const updateAlbumStatus = async (album: Album) => {
    const response = await axios(`/albums/update_status/`, {
      method: "put",
      data: { id: album.id, takedown: album.takedown, create_offer: album.createOffer }
    })

    const updatedResults = [
      ...state.paginatedAlbums.results.map((result) => {
        if (result.id === album.id) {
          result.takedown = album.takedown
          result.createOffer = album.createOffer
        }
        return result
      })
    ]

    dispatch({
      type: UPDATE_ALBUM_TAKEDOWN_STATUS,
      payload: { ...state, paginatedAlbums: { ...state.paginatedAlbums, results: updatedResults } }
    })

    return response.data.success
  }

  const updateAlbumCreateOfferList = async (idList: number[]) => {
    const response = await axios(`/albums/update_create_offer_list/`, {
      method: "put",
      data: { id_list: idList }
    })

    const updatedResults = [
      ...state.paginatedAlbums.results.map((result) => {
        if (idList.includes(result.id)) {
          result.createOffer = true
        }
        return result
      })
    ]

    dispatch({
      type: UPDATE_ALBUM_TAKEDOWN_STATUS,
      payload: { ...state, paginatedAlbums: { ...state.paginatedAlbums, results: updatedResults } }
    })

    return response.data.success
  }

  const importChartMetricTimeline = async (
    albumId: number,
    artistId: number,
    convertListToSnackBar?: (logs: string[]) => React.ReactElement<any, string | React.JSXElementConstructor<any>>
  ) => {
    try {
      const resp = await axios(`/albums/import_chart_metric_timeline/?album_id=${albumId}&artist_id=${artistId}`, {
        method: "post"
      })

      if (!resp.data.result) {
        toastMessage(`Error using Chartmetric API. Please retry. If the error continues please contact.`, "error", 5000)
        return false
      }

      const jobReport: JobReport = mapToCamelCase(resp.data.result)
      if (jobReport.insertCount > 0 && convertListToSnackBar) {
        toastMessage(`Successfuly imported ${jobReport.insertCount} timeline data`, "success", 5000)
      }
      if (jobReport.errorLogs?.length > 0 && convertListToSnackBar) {
        toastMessage(convertListToSnackBar(jobReport.errorLogs), "error", 5000)
      }
      if (jobReport.warningLogs?.length > 0 && convertListToSnackBar) {
        toastMessage(convertListToSnackBar(jobReport.warningLogs), "warning", 5000)
      }
    } catch (err) {
      toastMessage(`Error using Chartmetric API. Please retry. If the error continues please contact.`, "error", 5000)
      return false
    }
    return true
  }

  return (
    <AlbumsContext.Provider
      value={{ ...state, fetchAlbums, updateAlbumStatus, updateAlbumCreateOfferList, importChartMetricTimeline }}
    >
      {children}
    </AlbumsContext.Provider>
  )
}

export default AlbumsContext
