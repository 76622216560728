import { useContext } from "react"
import SystemContext from "../contexts/SystemContext"

const useSystem = () => {
  const context = useContext(SystemContext)

  if (!context) throw new Error("context must be use inside provider")

  return context
}

export default useSystem
