import Hashids from "hashids"
import { format, parse, parseISO } from "date-fns"
import { BaseVariant, enqueueSnackbar, SnackbarMessage } from "notistack"
import { CurrencyMap } from "./constants"

export const getValueText = (value: number | string) => {
  if (typeof value === "string") {
    return value
  }
  return value > 999999 ? `${(value / 1000000).toFixed(1)}M` : value > 999 ? `${(value / 1000).toFixed(1)}K` : value
}

export const getFilterText = (filter: any) => {
  if (!filter) return ""
  const stringifiedFilters = Object.keys(filter)
    .filter((key) => filter[key] !== undefined)
    .map((key, idx) => `${key}=${filter[key]}${Object.keys(filter).length - 1 === idx ? "" : "&"}`)
  let endString = ""
  for (const filterString of stringifiedFilters) {
    endString = endString.concat(filterString)
  }

  if (!endString) {
    return ""
  }
  return `&${endString}`
}

export const getDate = (date: string) => {
  try {
    return format(parse(date, "yyyy-LL-dd", new Date()), "dd/LL/yyyy")
  } catch (e) {
    try {
      return format(parseISO(date), "dd/LL/yyyy")
    } catch (e) {
      return date
    }
  }
}

export const formatNumberComma = (text: string) => {
  if (!text) {
    return ""
  }
  const numericValue = text.replace(/\D/g, "")
  return numericValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

export const toastMessage = (message: string | SnackbarMessage, variant: BaseVariant, autoHideDuration?: number) => {
  enqueueSnackbar(message, {
    autoHideDuration: autoHideDuration || 2000,
    anchorOrigin: { vertical: "top", horizontal: "right" },
    variant
  })
}

export const hashids = new Hashids("What comes first, goes last", 10)

export const turkishToEnglish = (name: string) => {
  // Normalize the string
  const normalizedName = name
    .normalize("NFKD")
    .replace(/[\u0300-\u036f]/g, "") // Remove diacritical marks
    .toLowerCase()

  // Replace spaces with hyphens
  return normalizedName.replace(/\s+/g, "-")
}

export const generateRandomColors = (count: number): string[] => {
  const colors: string[] = []
  for (let i = 0; i < count; i++) {
    const color = `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(
      Math.random() * 255
    )}, 0.6)`
    colors.push(color)
  }
  return colors
}

export const getCurrency = (value?: string) => {
  const currencyString = value || "N/A"
  //@ts-ignore
  return Object.keys(CurrencyMap).includes(currencyString) ? CurrencyMap[currencyString] : "?"

}
